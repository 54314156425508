<template>
    <AppModal :visible="visible" title="Invite Employee" content-class="reject-modal" @close="close">
        <CContainer class="invite-employee-container">
            <CRow :xs="{ cols: 1, gutter: 3 }" :md="{ cols: 2, gutter: 4 }">
                <CCol>
                    <CFormLabel class="w-100">First Name</CFormLabel>
                    <CInputGroup>
                        <CFormInput v-model="inviteData.first_name" placeholder="First Name"
                                    :invalid="!!validationErrors.first_name"
                                    @blur="validateField(inviteData,this.inviteRules, 'first_name')"/>
                    </CInputGroup>
                    <CFormText class="form-error">{{ validationErrors.first_name }}</CFormText>
                </CCol>
                <CCol>
                    <CFormLabel class="w-100">Last Name</CFormLabel>
                    <CInputGroup>
                        <CFormInput v-model="inviteData.last_name" placeholder="Last Name"
                                    :invalid="!!validationErrors.last_name"
                                    @blur="validateField(inviteData,this.inviteRules, 'last_name')"/>
                    </CInputGroup>
                    <CFormText class="form-error">{{ validationErrors.last_name }}</CFormText>
                </CCol>
                <CCol>
                    <CFormLabel style="width: 100%">Email</CFormLabel>
                    <CInputGroup>
                        <CFormInput v-model="inviteData.email" placeholder="Email"
                                    :invalid="!!validationErrors.email"
                                    @blur="validateField(inviteData,this.inviteRules, 'email')"/>
                    </CInputGroup>
                    <CFormText class="form-error">{{ validationErrors.email }}</CFormText>
                </CCol>
                <CCol>
                    <CFormLabel class="w-100">Role</CFormLabel>
                    <CSelectSearch v-model="inviteData.role_id" :label="'Role'" :options="roleOptions"
                                   :error="validationErrors.role_id" :placeholder="'Select Role'"
                                   @touchField="validateField(inviteData,this.inviteRules, 'role_id')"/>
                </CCol>
                <CCol>
                    <CFormLabel class="w-100">Profession</CFormLabel>
                    <CSelectSearch v-model="inviteData.profession_id" :label="'Role'" :options="professionOptions"
                                   :error="validationErrors.profession_id" :placeholder="'Select Profession'"
                                   @touchField="validateField(inviteData,this.inviteRules, 'profession_id')"/>
                </CCol>
                <CCol class="pt-3">
                    <CFormLabel class="w-100" v-if="enableDefaultContractSigner">
                        <input type="checkbox" v-model="inviteData.is_default_contract_signer"/>
                        Is the default contract signer?
                    </CFormLabel>
                    <CFormLabel class="w-100" v-if="enableDefaultTaskAssignee">
                        <input type="checkbox" v-model="inviteData.is_default_task_assignee"/>
                        Is the default task assignee?
                    </CFormLabel>
                </CCol>
            </CRow>
            <CRow class="mt-4">
                <CCol>
                    <p class="text-information small text-center">{{ textInformation }}</p>
                </CCol>
            </CRow>
        </CContainer>
        <template #footer>
            <CButton color="primary" variant="outline" @click="close">Cancel</CButton>
            <CButton class="" color="primary" @click="submit">Send</CButton>
        </template>
    </AppModal>
</template>

<script>
import CSelectSearch from '@/components/CSelectSearch';
import AppModal from "@/components/Modals/AppModal.vue";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import Roles from "@/api/v2/endpoints/Roles";
import Professions from "@/api/v2/endpoints/Professions";
import rules from "@/utils/validator/rules";
import UserInvitations from "@/api/v2/endpoints/UserInvitations";

const DEFAULT_TEXT_INFORMATION = 'This will send an invitation via email for a member of your team to sign up and join your Contracts Connected account.';

const formData = (companyId) => ({
    first_name: null,
    last_name: null,
    email: null,
    role_id: null,
    profession_id: null,
    is_default_contract_signer: false,
    is_default_task_assignee: false,
    company_id: companyId
});

export default {
    name: 'InviteEmployeeModal',
    components: {
        CSelectSearch,
        AppModal
    },
    mixins: [apiErrorHandler],
    inject: ['toast'],
    emits: ['close', 'success'],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        textInformation: {
            type: String,
            default: DEFAULT_TEXT_INFORMATION
        },
        enableDefaultContractSigner: {
            type: Boolean,
            default: false
        },
        enableDefaultTaskAssignee: {
            type: Boolean,
            default: false
        },
        redirectToListAfterSaving: {
            type: Boolean,
            default: false
        },
        companyId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            professions: [],
            roles: [],
            inviteData: formData(this.companyId),
            inviteRules: {
                first_name: [rules.required, rules.strMax(200)],
                last_name: [rules.required, rules.strMax(200)],
                email: [rules.required, rules.strMax(100), rules.email],
                role_id: [rules.required],
                profession_id: [rules.required],
            },
        }
    },
    computed: {
        roleOptions() {
            return this.roles.map(
                role => ({value: role.id, label: role.show_name})
            )
        },
        professionOptions() {
            return this.professions.map(
                profession => ({value: profession.id, label: profession.title})
            )
        },
    },
    mounted() {
        this.loadRoles();
        this.loadProfessions();
    },
    methods: {
        loadRoles() {
            Roles
                .index({company_id: this.companyId})
                .then(response => this.roles = response.data);
        },
        loadProfessions() {
            Professions.index().then(response => this.professions = response.data);
        },
        submit() {
            this.validateAndSubmit(this.inviteData, this.inviteRules, () => {
                this.toggleLoading();

                UserInvitations
                    .store(this.inviteData)
                    .then(() => {
                        this.toast('success', 'Employee successfully invited!');
                        this.$emit('success');
                        this.redirectAfterSaving();
                        this.close();
                    })
                    .catch(response => this.handleApiError(response))
                    .finally(() => this.toggleLoading())
            })
        },
        clearForm() {
            Object.assign(this.inviteData, formData(this.companyId));
        },
        close() {
            this.clearForm();
            this.clearValidationErrors();
            this.$emit('close');
        },
        redirectAfterSaving() {
            if (this.redirectToListAfterSaving && this.$route.name !== 'InvitedEmployees') {
                this.$router.push({name: 'InvitedEmployees'});
            }
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    },
}
</script>
<style lang="scss" scoped>
.invite-employee-container {
    max-width: 800px;

    .form-error {
        color: red;
    }
}
</style>
